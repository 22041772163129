import React from 'react';

function HamburgerMenu({ title, titleid, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" aria-labelledby={titleid} {...props}>
      {title ? <title id={titleid}>{title}</title> : null}
      <g fill="currentColor" fillRule="evenodd" transform="translate(3 5)">
        <rect width={18} height={2} rx={1} />
        <rect width={18} height={2} y={6} rx={1} />
        <rect width={18} height={2} y={12} rx={1} />
      </g>
    </svg>
  );
}

export default HamburgerMenu;
