import React, { createContext, useState, useEffect, useCallback } from 'react';
import getConfig from 'next/config';
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction';
import { sendGaEventToAmplitude, pushDataLayerToAmplitude } from '../../lib/amplitude';
import { CustomiseVoucherContext } from '../Context/Context';

const EventTrackingContext = createContext({
  track: () => {},
});

const {
  publicRuntimeConfig: { AMPLITUDE_TOKEN },
} = getConfig();

const { Provider, Consumer } = EventTrackingContext;

const EventTrackingProvider = ({ children, track, isLoggedIn, userId }) => {
  const [amplitudeInstance, setAmplitudeInstance] = useState(null);

  const initAmplitude = useCallback(() => {
    let userid = '';
    if (isLoggedIn && userId) {
      userid = userId;
    }
    import('amplitude-js').then((amplitude) => {
      amplitude.getInstance().init(AMPLITUDE_TOKEN, userid);
      setAmplitudeInstance(amplitude);
      window.fp_amplitude = amplitude;
      pushDataLayerToAmplitude(amplitude);
    });
  }, [isLoggedIn, userId]);

  useEffect(() => {
    initAmplitude();
  }, [initAmplitude]);

  const trackEvent = useCallback(
    (pushEvent) => {
      // eslint-disable-next-line camelcase
      const { page_type, screen_name, event_category, event_action, event_label, device_id } =
        pushEvent;
      const GTEvent = {
        [GEV.LOGIN_STATUS]: isLoggedIn ? 'Yes' : 'No',
        [GEV.PAGE_TYPE]: page_type,
        [GEV.SCREEN_NAME]: screen_name,
        [GEV.EVENT_CAT]: event_category,
        [GEV.EVENT_ACTION]: event_action,
        [GEV.EVENT_LABEL]: event_label,
        [GEV.ASSET_TYPE]: 'payment-egifting',
        [GEV.USER_ID]: userId,
        [GEV.DEVICE_ID]: device_id,
        [GEV.ENVIRONMENT]: process.env.GA_ENV,
        [GEV.EVENT_NAME]: 'hm_push_event',
      };
      // send event to GA
      window.dataLayer.push(GTEvent);
      sendGaEventToAmplitude(pushEvent, amplitudeInstance);
    },
    [isLoggedIn, userId, amplitudeInstance]
  );

  return <Provider value={{ track: track || trackEvent }}>{children}</Provider>;
};

const EventTrackingProviderWrapper = (props) => (
  <CustomiseVoucherContext.Consumer>
    {({ isLoggedIn, userId }) => (
      <EventTrackingProvider {...props} isLoggedIn={isLoggedIn} userId={userId} />
    )}
  </CustomiseVoucherContext.Consumer>
);

EventTrackingProviderWrapper.defaultProps = {};

export const EventTrackingConsumer = Consumer;
export { EventTrackingContext };

export default EventTrackingProviderWrapper;
