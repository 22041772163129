const isProd = process.env.NODE_ENV === 'production';

const BUSINESS_HEADER = 'E-Gift Vouchers for Business';
const PERSONAL_HEADER = 'Personal E-Gift Vouchers';

const newContactUsUrl = isProd
  ? 'https://docs.google.com/forms/d/e/1FAIpQLSfG9jq2B2OHlVy7PZ7hfa-BLNeusrhi5YCJKimuMpYJN60S0Q/viewform'
  : 'https://docs.google.com/forms/d/e/1FAIpQLScFat1wH0aMIzqRUH0_ekcdKl3lqhXGU8sbbCQ0L2ESyiS7ZA/viewform';

const MOBILE_NAVIGATION_B2B = [
  {
    label: PERSONAL_HEADER,
    url: '',
    isNewTab: false,
  },
  {
    label: BUSINESS_HEADER,
    url: '',
    isNewTab: false,
    subMenu: 'My orders',
    subMenuLink: '/my-orders/',
  },
  {
    label: 'Help',
    url: 'https://help.fairprice.com.sg/',
  },
  {
    label: 'Contact us',
    url: newContactUsUrl,
  },
];

const MOBILE_NAVIGATION_B2B_NO_ENTITY = [
  {
    label: PERSONAL_HEADER,
    url: '',
    isNewTab: false,
  },
  {
    label: BUSINESS_HEADER,
    url: '',
    isNewTab: false,
  },
  {
    label: 'Help',
    url: 'https://help.fairprice.com.sg/',
  },
  {
    label: 'Contact us',
    url: newContactUsUrl,
  },
];

const NON_LOGIN_B2B = [
  {
    label: PERSONAL_HEADER,
    url: '',
    isNewTab: false,
  },
  {
    label: BUSINESS_HEADER,
    url: '',
    isNewTab: false,
  },
  {
    label: 'Help',
    url: 'https://help.fairprice.com.sg/',
  },
  {
    label: 'Contact us',
    url: newContactUsUrl,
  },
];

const MOBILE_NAVIGATION_B2C = [
  {
    label: PERSONAL_HEADER,
    url: '',
  },
  {
    label: BUSINESS_HEADER,
    url: '',
  },
  {
    label: 'Help',
    url: 'https://help.fairprice.com.sg/',
  },
  {
    label: 'Contact us',
    url: newContactUsUrl,
  },
];

module.exports = {
  newContactUsUrl,
  MOBILE_NAVIGATION_B2B,
  MOBILE_NAVIGATION_B2B_NO_ENTITY,
  MOBILE_NAVIGATION_B2C,
  NON_LOGIN_B2B,
};
