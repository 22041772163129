import React from 'react';

function MobileNavClose({ title, titleid, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" aria-labelledby={titleid} {...props}>
      {title ? <title id={titleid}>{title}</title> : null}
      <g fill="none">
        <path d="M5 5h14v14H5z" />
        <path stroke="#696969" strokeLinecap="round" strokeWidth={2} d="M5 5l14 14" />
        <path d="M19 5H5v14h14z" />
        <path stroke="#696969" strokeLinecap="round" strokeWidth={2} d="M19 5L5 19" />
      </g>
    </svg>
  );
}

export default MobileNavClose;
