import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body {
    min-height:100vh;
    background-color: #ffffff;
    font-family: "Lato",sans-serif,
  }
 
  p,span{
    word-break: break-word;
  }

  /* stylelint-disable */
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    color-adjust: exact !important;                 /*Firefox*/
  }

  .webR-text-primary {
    color: #0c3677;
  }

  .webR-text-preserve-space {
    white-space: pre-wrap;
  }

  .webR-text--error {
    color:#c10b3a !important;
  }

  [data-testid="pageContent"] {
    min-height: 60vh;
  }

  .webR-grid-item-compact {
    padding-left:0 !important;
    padding-right:0 !important;;
  }

  .text-center: {
    text-align: center !important;
  }

  .WebR--hidden {
    display: none !important;
  }

  .webR-clear-both:after {
    content:"";
    display:block;
    clear: both;
  }

  /* lato-regular - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://media.nedigital.sg/nedigital/files/daec682d-3287-405a-aa79-96223c4b497f/lato-v16-latin-ext_latin-regular.eot');
  /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'), url('https://media.nedigital.sg/nedigital/files/daec682d-3287-405a-aa79-96223c4b497f/lato-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
    url('https://media.nedigital.sg/nedigital/files/9cf5fe64-979a-46af-a184-af1cd2c77395/lato-v16-latin-ext_latin-regular.woff2') format('woff2'),
  /* Super Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/9fc0d407-b429-4c2d-9790-860be8ea8fa9/lato-v16-latin-ext_latin-regular.woff') format('woff'),
  /* Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/3a1f5374-6aba-42e9-936c-e11e05682f7d/lato-v16-latin-ext_latin-regular.ttf') format('truetype'),
  /* Safari, Android, iOS */
    url('https://media.nedigital.sg/nedigital/files/f38150ff-ee7f-44f8-9837-9f811f0ff1d6/lato-v16-latin-ext_latin-regular.svg#Lato') format('svg');
  /* Legacy iOS */
  }
  /* lato-700 - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://media.nedigital.sg/nedigital/files/f8cbf2f4-4d0c-4989-af28-4c0c07c03925/lato-v16-latin-ext_latin-700.eot');
  /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'), url('https://media.nedigital.sg/nedigital/files/f8cbf2f4-4d0c-4989-af28-4c0c07c03925/lato-v16-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
    url('https://media.nedigital.sg/nedigital/files/062804f9-65e6-46df-b4a3-69d515ee444c/lato-v16-latin-ext_latin-700.woff2') format('woff2'),
  /* Super Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/67fb7ecc-87c0-4613-aa21-892f5d5678cb/lato-v16-latin-ext_latin-700.woff') format('woff'),
  /* Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/0270b03c-3d43-4e17-9402-8d3a1bfab940/lato-v16-latin-ext_latin-700.ttf') format('truetype'),
  /* Safari, Android, iOS */
    url('https://media.nedigital.sg/nedigital/files/10ccbb7c-f796-4689-a0f6-9bec2c42fc8d/lato-v16-latin-ext_latin-700.svg#Lato') format('svg');
  /* Legacy iOS */
  }
  /* lato-900 - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('https://media.nedigital.sg/nedigital/files/686e1a69-f900-404f-8011-c7cc8768717e/lato-v16-latin-ext_latin-900.eot');
  /* IE9 Compat Modes */
    src: local('Lato Black'), local('Lato-Black'), url('https://media.nedigital.sg/nedigital/files/686e1a69-f900-404f-8011-c7cc8768717e/lato-v16-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
    url('https://media.nedigital.sg/nedigital/files/72173d62-62f2-4965-85d2-98262c76f0af/lato-v16-latin-ext_latin-900.woff2') format('woff2'),
  /* Super Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/ec8552d4-383a-4dde-8e47-07adcce35f9a/lato-v16-latin-ext_latin-900.woff') format('woff'),
  /* Modern Browsers */
    url('https://media.nedigital.sg/nedigital/files/c6acdf36-3c71-4a1f-8587-be2067ed1f30/lato-v16-latin-ext_latin-900.ttf') format('truetype'),
  /* Safari, Android, iOS */
    url('https://media.nedigital.sg/nedigital/files/5c3ed274-1d25-43af-a4c4-83f4bf977a08/lato-v16-latin-ext_latin-900.svg#Lato') format('svg');
  /* Legacy iOS */
  }
`;
