export const cartInitialState = {
  items: [],
  isLoading: false,
};

export default function cartReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_ITEMS':
      return { ...state, items: payload, isLoading: false };
    case 'LOADING_ITEMS':
      return { ...state, isLoading: true };
    case 'UNLOADING_ITEMS':
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
