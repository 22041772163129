import React from 'react';
import { Modal, ModalContent, SvgClose, Typography } from '@ntuctech/devex-tangram';
import styled from 'styled-components';
import personal from './images/personal.svg';
import business from './images/business.svg';
import success from './images/Success.svg';
import { SCREEN_SIZE } from '../../../lib/mediaQuery';

const StyledTitle = styled(Typography)`
  color: var(--mine-shaft-700, var(--colour-mineshaft-700, #333));
  font-feature-settings: 'clig' off, 'liga' off;
  padding-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  width: 29.5rem !important;
  padding: 2rem !important;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  background-color: var(--colour-base-white, #fff) !important;
  gap: 2rem;
  flex-wrap: wrap;
  .center {
    text-align: center;
  }
  ${SCREEN_SIZE.Below.Tablet} {
    width: 29.5rem !important;
    padding: 1rem !important;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 1rem !important;
  }
`;

const StyledSelection = styled.div`
  cursor: pointer;
  position: relative;
  width: 213px;
  background-color: ${(props) =>
    props.isActive ? '#F1F6FE' : 'var(--colour-base-white, #fff) !important'};
  height: 184px;
  padding: 1.5rem 1rem;
  border-radius: 0.75rem;
  border: ${(props) =>
    props.isActive ? '3px solid #1454B8' : '1px solid var(--mine-shaft-80, #eaeaea)'};
  img {
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
    display: flex;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 1rem;
  }
`;

const StyledTypography = styled(Typography)`
  margin-top: 0.25rem;
  text-align: center;
`;

const StyleedSubTitle = styled.div`
  text-align: center;
`;

const SelectionContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  .margin-right-24 {
    margin-right: 1.5rem;
    ${SCREEN_SIZE.Below.MobileL} {
      margin-right: 0.5rem;
    }
  }
  ${SCREEN_SIZE.Below.Tablet} {
    margin-top: 1rem;
  }
`;

const SuccessImg = styled.img`
  width: 2.5rem !important;
  height: 2.5rem !important;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem !important;
  ${SCREEN_SIZE.Below.MobileL} {
    margin: 0 !important;
  }
`;

const Popup = ({ open, onSelection, deliveryModeB2B, onClose }) => {
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 767) {
        onClose();
      }
    });

    return () => window.removeEventListener('resize', () => {});
  }, []);

  return (
    <StyledModal open={open}>
      <ModalContent>
        <StyledTitle variant="bodyLRegular">
          Welcome to FairPrice E-Gift Portal
          <SvgClose data-testid="close-popup" onClick={onClose} />
        </StyledTitle>
        <Typography variant="titleM">Who are you shopping for today?</Typography>
        <SelectionContainer>
          <StyledSelection
            data-testid="b2c-button"
            className="margin-right-24"
            isActive={!deliveryModeB2B}
            onClick={() => onSelection('B2C')}
          >
            {!deliveryModeB2B && <SuccessImg src={success.src} alt={success} />}
            <img src={personal.src} alt={personal} />
            <StyledTypography variant="titleL">Personal</StyledTypography>
            <StyleedSubTitle>
              <Typography variant="bodyS">For friends and family</Typography>
            </StyleedSubTitle>
          </StyledSelection>
          <StyledSelection
            data-testid="b2b-button"
            isActive={deliveryModeB2B}
            onClick={() => onSelection('B2B')}
          >
            {deliveryModeB2B && <SuccessImg src={success.src} alt={success} />}
            <img src={business.src} alt={personal} />
            <StyledTypography variant="titleL">Business</StyledTypography>
            <StyleedSubTitle>
              <Typography variant="bodyS">For corporate gifting</Typography>
            </StyleedSubTitle>
          </StyledSelection>
        </SelectionContainer>
      </ModalContent>
    </StyledModal>
  );
};

export default Popup;
