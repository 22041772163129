import { createContext, useContext } from 'react';
import { cartInitialState } from '../reducers/cartReducer';

const CartContext = createContext({
  cartState: cartInitialState,
});

export const useCartContext = () => useContext(CartContext);

export default CartContext;
