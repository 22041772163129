import React from 'react';
import styled from 'styled-components';
import Header from '../../../containers/Header';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .no-spacing-layout {
    padding: 0;
    margin: 0;
  }
`;

function Layout({ children, className }) {
  return (
    <Container className={className} data-testid="layout">
      <Header />
      {children}
    </Container>
  );
}

export default Layout;
