import React from 'react';
import styled from 'styled-components';
import PageHeader from '../../components/Theme/PageHeader';

const StyledHeader = styled.header`
  height: auto;
`;

const Header = () => (
  <StyledHeader data-testid="pageHeader">
    <PageHeader />
  </StyledHeader>
);

export default Header;
