import React, { useState, useContext, useEffect } from 'react';

export const FeatureFlagContext = React.createContext();

const timeoutForSplitInMSec = 0;

export const useFeatureFlag = () => useContext(FeatureFlagContext);

const FeatureFlagProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, timeoutForSplitInMSec);
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        isReady,
        setIsReady,
      }}
    >
      <>{children}</>
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
