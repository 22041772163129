import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Typography } from '@ntuctech/devex-tangram';

import { SCREEN_SIZE } from '../../../lib/mediaQuery';
import InactiveCartIcon from '../../icons/CartNewInactive';
import CartIcon from '../../icons/CartNewActive';

const StyledCartText = styled(Typography)`
  color: ${(p) => (p.deliveryModeB2B ? '#fff !important;' : '#1454b8 !important')};
  display: none;

  ${SCREEN_SIZE.From.Tablet} {
    display: inline-block;
  }
`;

const StyledCartIconWrapper = styled.span`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;

  ${SCREEN_SIZE.From.Tablet} {
    margin-right: 0.25rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin: 12px;
  }
`;

const StyledCartButton = styled.a`
  #cart-text {
    display: inline;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    #cart-text {
      display: none;
    }
  }
  font-size: 1rem;
  line-height: 1.5;
  color: #1557bf;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  ${SCREEN_SIZE.From.Tablet} {
    min-width: 4.125rem;
  }
  span {
    display: inline-block;
    vertical-align: top;
  }
`;
const CartCount = styled.span`
  height: 1rem;
  max-width: 25px;
  position: absolute;
  background-color: #dd0d42;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.6875rem;
  line-height: 1.45;
  text-align: center;
  padding: 0rem 0.23rem;
  top: -0.48rem;
  left: 0.65rem;
`;

export const Cart = (props) => {
  const { count, deliveryModeB2B } = props;
  const router = useRouter();

  return (
    <StyledCartButton onClick={() => router.push('/cart/')} data-testid="cart-button">
      <StyledCartIconWrapper>
        {count > 0 && <CartCount> {count > 99 ? '99+' : count} </CartCount>}
        {count ? (
          <CartIcon
            width="1.5rem"
            height="1.5rem"
            title="Shopping cart"
            color={deliveryModeB2B ? '#fff' : '#1558be'}
          />
        ) : (
          <InactiveCartIcon
            width="1.5rem"
            height="1.5rem"
            title="Shopping cart"
            color={deliveryModeB2B ? '#fff' : '#1558be'}
          />
        )}
      </StyledCartIconWrapper>
      <StyledCartText id="cart-text" variant="bodyMBold" deliveryModeB2B={deliveryModeB2B}>
        Cart
      </StyledCartText>
    </StyledCartButton>
  );
};
Cart.defaultProps = {
  count: 0,
};

export default Cart;
